<template>
    <div class="home-page">
        <Header/>
        <div class="banner">
            <img src="../assets/bdata/banner.jpeg">
        </div>
        <div class="main-page">
            <div class="title">
                <span class="blankF">大数据财务分析</span>是什么?
                <img src="../assets/bdata/line.png">
            </div>
            <div class="content content-1">
                <p>对组织内外部规模巨大的财务相关数据（除财务数据和结构化数据之外，也包括大量的非财务数据和非结构化数据）进行收集、准备、分析、行动的一套专门技术和方法，是会计学科和信息学科跨界融合的产物。</p>
                <img src="../assets/bdata/tc10.png">
            </div>

            <div class="title">
                <span class="blankF">职业技能</span>等级标准
                <img src="../assets/bdata/line.png">
            </div>
            <div class="djbz-title" style="margin-top:37px">【初级大数据财务分析】</div>
            <p class="djbz-p">初步掌握大数据思维，具备运用基础数据技能在工作中从事数据初始准备、数据收集整理、数据工具选择、财务与业务应用场景辨识的能力，并具备大数据基本职业操守。</p>
            <div class="djbz-title">【中级大数据财务分析】</div>
            <p class="djbz-p">掌握大数据思维，具备在工作中发现财务与业务数据需求，并运用相关专业工具进行数据准备与清理、建模与分析、可视化呈现的能力，具备运用数据库工具生成财务报表的能力。</p>
            <div class="djbz-title">【高级大数据财务分析】</div>
            <p class="djbz-p">熟练运用大数据思维，具备运用数据分析工具与方法融合财务专业技能进行财务报表分析、业绩管理分析、审计分析及内部控制系统分析的能力，为经营管理层提供专业建议，做好决策参谋。</p>

            <div class="title">
                <span class="blankF">大数据财务分析</span>课程设置
                <img src="../assets/bdata/line.png">
            </div>
            <div class="shixun">
                <div class="cont left">
                    <div class="b" style="margin-top:38px;margin-bottom:22px">导论 大数据财务分析概述</div>
                    <div class="b">项目一 营业收入数据分析</div>
                    <div class="s">任务一 销售数据加工处理</div>
                    <div class="s">任务二 销售收入统计分析</div>
                    <div class="s">任务三 可视化处理</div>

                    <div class="b">项目二 企业利润率分析</div>
                    <div class="s">任务一 利润率统计分析 </div>
                    <div class="s">任务二 数据可视化处理</div>
                    
                    <div class="b">项目三 运费成本分析</div>
                    <div class="s">任务一 运输物流费用对比分析 </div>
                    <div class="s">任务二 运输物流成本可视化</div>

                    <div class="b">项目四 预算执行分析</div>
                    <div class="s">实验一 预算执行分析 </div>
                    <div class="s">任务二 预算执行分析可视化</div>
                </div>
                <div class="cont right">
                    <div style="margin-top:38px;" class="b">项目五 采购预测分析</div>
                    <div class="s">任务一 用移动平均算法预测采购量</div>

                    <div class="b">项目六 差旅费审计分析</div>
                    <div class="s">任务一 出差任务分析</div>
                    <div class="s">任务二 可视化操作</div>
                    <div class="s">任务三 差旅费行程冲突审计</div>
                    <div class="s">任务四 差旅费预测分析</div>
                    
                    <div class="b">项目七 财务报表分析</div>
                    <div class="s">任务一 XRBL财务数据解析 </div>
                    <div class="s">任务二 可视化操作</div>
                    <div class="s">任务三 资产负债分析</div>

                    <div class="b">项目八 财务数据动态分析</div>
                    <div class="s">任务一 财务数据动态更新管理 </div>
                    <div class="s">任务二 五大财务比率分析</div>
                    <div class="s">任务三 利润表可视化-散点图</div>
                    <div class="s">任务四 现金流量表可视化</div>
                </div>
            </div>

            <div class="title">
                实训平台特点
                <img src="../assets/bdata/line.png">
            </div>
            <img style="width:100%;margin-top:32px" src="../assets/bdata/tc6.png">
            

            <div class="title">
                财务数据可视化
                <img src="../assets/bdata/line.png">
            </div>
            <p style="font-size: 16px;margin-top:34px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #000000;
line-height: 24px;">枯燥的财务数据和报表，通过DEEP平台进行数据加工、数据清洗、数据建模等流程，最终以可视化的效果
呈现并加以分析，企业数据动态可见。</p>
            <img style="width:100%;margin-top:45px" src="../assets/bdata/map.png">

            <div class="title">
                证书教育部查询
                <img src="../assets/bdata/line.png">
            </div>
            <img style="width:100%;margin-top:45px" src="../assets/bdata/tc11.png">
            <div style="text-align:center;font-size: 16px;
font-family: Microsoft YaHei;
font-weight: 400;margin-top:38px;
color: #000000;">教育部指定官方查询网址：https://vslc.ncb.edu.cn/
</div>

            <div class="title">
                证书样本
                <img src="../assets/bdata/line.png">
            </div>
            <img style="width:100%;margin-top:32px" src="../assets/bdata/cer.png">

            <div class="title">
                证书实施流程
                <img src="../assets/bdata/line.png">
            </div>
            <p style="font-size: 16px;margin-top:34px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #000000;
line-height: 24px;">枯燥的财务数据和报表，通过DEEP平台进行数据加工、数据清洗、数据建模等流程，最终以可视化的效果
呈现并加以分析，企业数据动态可见。</p>
            <img style="width:100%;margin-top:45px" src="../assets/bdata/sslc.png">

            <div class="title">
                师资培训
                <img src="../assets/bdata/line.png">
            </div>
            <p style="font-size: 16px;margin-top:78px;font-family: Microsoft YaHei;font-weight: 400;color: #000000;line-height: 24px;">
                大数据财务分析师资培训采取“线上+线下”、“理论+实践”、“大数据+会计”六结合的方式，采取情景式案例教学方式，以任务驱动教学的方法，让参训教师系统学习大数据财务分析的相关基础理论体系及规模化生产型企业各部门业务与大数据结合的应用场景。
            </p>
            <div style="margin-top: 40px" class="img-list">
                <img src="../assets/bdata/1.png">
                <img src="../assets/bdata/河南.png">
                <img src="../assets/bdata/第三期.png">
                <img src="../assets/bdata/2020.png">
                <img src="../assets/bdata/wx.png">
                <img src="../assets/bdata/第六期.png">
                <img src="../assets/bdata/第七期.png">
                <img src="../assets/bdata/2021.png">
            </div>

            <div class="title">
                考核站点建设
                <img src="../assets/bdata/line.png">
            </div>
            <p class="kaodian" style="font-weight: bold;margin-top:34px">1：考试系统由培训评价组织免费提供</p>
            <p class="kaodian" style="font-weight: bold;">2：计算机配置：</p>
            <p class="kaodian" >（1）处理器（CPU）主频≥2.0GHz；</p>
            <p class="kaodian" >（2）内存≥8GB；</p>
            <p class="kaodian" >（3）硬盘容量≥500G；</p>
            <p class="kaodian" >（4）显示器尺寸≥19英寸；</p>
            <p class="kaodian" >（5）操作系统Win7及以上</p>
            <p class="kaodian" style="font-weight: bold;">3：软件环境要求</p>
            <p class="kaodian" >（1）系统要求必须是window7、window10及其以上，64位，磁盘剩余空间超过100G及其以上；</p>
            <p class="kaodian" >（2）浏览器：正版谷歌51及以上版本。 网络要求： 网速带宽1000M及以上。</p>


 

            <div class="title">
                认可企业
                <img src="../assets/bdata/line.png">
            </div>
            <img style="width:100%;margin-top:45px;margin-bottom:100px" src="../assets/bdata/list.png">
        </div>
        
        <Footer/>
    </div>
</template>
<script>

export default {
    components:{
       
    },
    data() {
        return {
            
        }
    },
    beforeMount() {
        
    },
    methods: {
        
    },
}
</script>
<style lang="scss" scoped>
.djbz-title{
    display: inline-block;
    padding: 5px 13px;
    background: #00479D;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
}
.djbz-p{
    margin-bottom: 37px;
    margin-top: 28px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 24px;
}
.shixun{
    display: flex;
    margin-top: 36px;
    justify-content: space-between;
    .right{
        width:50%;
        height:740px;
        background: #E5E5E5;
    }
    .left{
        border:1px dashed #000;
        width:50%;
        height:740px;
        background: #E5E5E5;
    }
    .cont{
        .s{
            text-align: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #000000;
            margin-top: 18px;
        }
        .b{
            text-align: center;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #000000;
            margin-top: 32px;
        }
    }
}
.flex{
    display: flex;
    justify-content: space-between;
}
.content{
    width: 1200px;
    margin: auto;
}

.banner{
    width:100%;
    position: relative;
    img{
        width:100%;
    }
}

.kaodian{
    margin-top: 18px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    color: #000000;
}

.main-page{
    width: 1200px;
    margin: auto;
}

.title{
    margin-top: 55px;
    text-align: center;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #00479D;
    .blankF{
        color:#393939;
    }
    img{
        width:274px;
        display: block;
        margin: auto;
        margin-top: 16px;
    }
}

.img-list{
    display: flex;
    flex-flow: wrap;
    img{
        width:50%;
    }
}

.content{
    margin-top: 46px;
}
.content-1{
    display: flex;
    justify-content: space-between;
    p{
        width:719px
    }
    img{
        width: 444px;
    }
}



</style>
